import * as Sentry from '@sentry/react';
import {
  FETCH_GOOGLE_OAUTH2_AUTHORIZATION_CODE,
  FETCH_GOOGLE_OAUTH2_TOKENS,
  FETCH_GOOGLE_OAUTH2_TOKENS_FAILURE,
  FETCH_GOOGLE_OAUTH2_TOKENS_SUCCESS,
  FETCH_GOOGLE_OAUTH2_TOKENS_UNAUTHORIZED,
  LOGIN,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT,
} from './actionTypes';
import { createAction } from 'redux-actions';
import { Role } from 'types/types';

export interface LoginActionPayload {
  username: string;
  password: string;
}

export const login = createAction<LoginActionPayload>(LOGIN);

export interface LoginSuccessPayload {
  token: string;
  role: Role;
  user: {
    id: number;
    firstName: string;
    lastName: string;
  };
}
export interface FetchGoogleOauth2TokensSuccessPayload {
  access_token: string;
  refresh_token: string;
  id_token: string;
  user: Record<string, any>;
  role: string;
}

export const fetchGoogleOauth2AuthorizationCode = createAction(
  FETCH_GOOGLE_OAUTH2_AUTHORIZATION_CODE
);

export const fetchGoogleOauth2Tokens = createAction(FETCH_GOOGLE_OAUTH2_TOKENS);

export const fetchGoogleOauth2TokensSuccess = createAction(
  FETCH_GOOGLE_OAUTH2_TOKENS_SUCCESS
);

export const fetchGoogleOauth2TokensFailure = createAction(
  FETCH_GOOGLE_OAUTH2_TOKENS_FAILURE
);

export const fetchGoogleOauth2TokensUnauthorized = createAction(
  FETCH_GOOGLE_OAUTH2_TOKENS_UNAUTHORIZED
);

export const loginSuccess = createAction(LOGIN_SUCCESS);

export const loginFailure = createAction(LOGIN_FAILURE);

export const logoutAction = createAction(LOGOUT);
export const logout = () => {
  Sentry.setUser(null);
  // So user can't recover their authentication by refreshing right after logout
  localStorage.clear();
  return logoutAction();
};
