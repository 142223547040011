import { getAccessToken } from 'selectors/auth/getAccessToken';
import { GlobalState } from '../reducers';
import { getBasicToken } from '../selectors/auth/getBasicToken';

export const getAuthHeaders = (state: GlobalState) => {
  const accessToken = getAccessToken(state);
  const basicToken = getBasicToken(state);

  if (accessToken) {
    return {
      Authorization: `Bearer ${accessToken}`,
    };
  }

  return {
    Authorization: `Basic ${basicToken}`,
  };
};
