import React, { useCallback } from 'react';
import { Avatar } from 'components/Avatar/Avatar';
import { BugIcon } from 'components/icons/BugIcon';
import { CustomLink } from 'components/CustomLink/CustomLink';
import { connect, useSelector } from 'react-redux';
import { GlobalState } from 'reducers';
import { getIsBugButtonEnabled } from 'selectors/features/getIsBugButtonEnabled';
import toast from 'react-hot-toast';
import { getAuthHeaders } from 'api/getAuthHeaders';
import { api } from 'api/api';
import { throttle } from 'lodash';

interface StateProps {
  state: GlobalState;
}

type Props = StateProps;

const mapStateToProps = (state: GlobalState) => ({ state });

export const BugButtonComponent = ({ state }: Props) => {
  const isEnabled = useSelector(getIsBugButtonEnabled);
  const authHeaders = useSelector(getAuthHeaders);

  const onClick = throttle(() => {
    toast.promise(
      api({
        url: 'bug_report',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...authHeaders,
        },
        data: state,
      }),
      {
        loading: 'Sending bug report',
        success: 'Bug report sent',
        error: 'There was an error',
      }
    );
  }, 10000);

  if (!isEnabled) {
    return null;
  }

  return (
    <CustomLink
      style={{ position: 'absolute', bottom: 85, left: 10, zIndex: 9999 }}
      onClick={onClick}
    >
      <Avatar
        size={33}
        sx={{
          border: '2px solid red',
        }}
      >
        <BugIcon size={20} />
      </Avatar>
    </CustomLink>
  );
};

export const BugButton = connect(mapStateToProps)(BugButtonComponent);
