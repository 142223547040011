import { RisePopup } from 'components/RisePopup';
import { useFormik } from 'formik';
import { useConnectionStatus } from 'hooks/useConnectionStatus';
import { REQUEST_FORM } from 'modules/files/actionTypes';
import { fileTypeToUploadType } from 'modules/hooks/constants';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { Button, Text } from 'rebass';
import { AppFileType } from 'utils/file/constants';

export const AbnFormRequestButton = ({
  leadId,
  caseId,
  appFileType,
  resign,
}: {
  leadId: string;
  caseId: string;
  appFileType: AppFileType;
  resign?: boolean;
}) => {
  const [
    isShowingDataCollectionPopup,
    setIsShowingDataCollectionPopup,
  ] = useState(false);
  const formik = useFormik({
    initialValues: {
      reasonMedicareMayNotPay: '',
      medicareBillingPreference: '',
      physicalTherapyActivity: '',
      additionalInformation: '',
    },
    onSubmit: values => {
      if (
        !values.physicalTherapyActivity.trim() ||
        !values.medicareBillingPreference.trim() ||
        !values.reasonMedicareMayNotPay.trim() ||
        !parseInt(values.medicareBillingPreference.trim())
      ) {
        toast.error(
          'Please fill all fields before submitting the form request.'
        );
      } else {
        dispatch({
          type: REQUEST_FORM,
          payload: {
            leadId,
            caseId,
            form_type: fileName,
            embed: isOnline,
            abnFields: values,
          },
        });
      }
    },
  });
  const { isOnline } = useConnectionStatus();
  const dispatch = useDispatch();

  const fileName = fileTypeToUploadType[appFileType];

  const togglePopup = () => {
    setIsShowingDataCollectionPopup(!isShowingDataCollectionPopup);
  };

  const options = [
    'Patient exceeded maximum treatment',
    'This service has not been prescribed by his/her doctor',
    'The beneficiary is extending treatment without Doctor’s consent',
    'The beneficiary is requesting additional sessions',
    'These services are not considered to be Medically Necessary by the Doctor',
  ];

  return (
    <>
      <Text variant="link" onClick={togglePopup}>
        {resign ? 'Re-sign' : 'Sign'}
      </Text>
      {isShowingDataCollectionPopup ? (
        <RisePopup
          onClickOverlay={togglePopup}
          sx={{
            height: '100%',
            position: 'fixed',
            left: 0,
            bottom: 0,
            zIndex: 9999,
            display: 'flex',
          }}
          render={({ createHideHandler }) => {
            return (
              <form
                style={{ height: 'calc(100% - 65px)' }}
                onSubmit={(...args) => {
                  createHideHandler(togglePopup)();
                  formik.handleSubmit(...args);
                }}
              >
                <div style={{ height: '100%', overflow: 'auto' }}>
                  <fieldset
                    style={{
                      border: 'none',
                      padding: 0,
                      paddingTop: 10,
                      marginBottom: 15,
                    }}
                  >
                    <legend style={{ fontWeight: 'bold' }}>
                      Physical Therapy
                    </legend>
                    <textarea
                      id="physicalTherapyActivity"
                      name="physicalTherapyActivity"
                      onChange={formik.handleChange}
                      placeholder="Please write the services and/or modalities provided"
                      value={formik.values.physicalTherapyActivity}
                      style={{
                        width: '100%',
                        borderRadius: 4,
                        borderColor: '#aaa',
                        height: 120,
                        outline: 'none',
                        padding: 8,
                        resize: 'none',
                      }}
                    />
                  </fieldset>
                  <fieldset
                    style={{
                      border: 'none',
                      padding: 0,
                      paddingTop: 10,
                      marginBottom: 15,
                    }}
                  >
                    <legend style={{ fontWeight: 'bold' }}>
                      Reason Medicare May Not Pay
                    </legend>
                    {options.map(option => (
                      <div
                        key={option}
                        style={{ display: 'flex', marginBottom: 12 }}
                      >
                        <input
                          id={option.replace(' ', '')}
                          type="radio"
                          name="reasonMedicareMayNotPay"
                          value={option}
                          onChange={formik.handleChange}
                        />
                        <label
                          style={{ flex: 1 }}
                          htmlFor={option.replace(' ', '')}
                        >
                          {option}
                        </label>
                      </div>
                    ))}
                  </fieldset>
                  <fieldset
                    style={{
                      border: 'none',
                      padding: 0,
                      paddingTop: 10,
                      marginBottom: 15,
                    }}
                  >
                    <legend style={{ fontWeight: 'bold' }}>
                      Medicare Billing Preference
                    </legend>
                    <div>
                      <input
                        type="radio"
                        id="option1"
                        name="medicareBillingPreference"
                        value="1"
                        onChange={formik.handleChange}
                      />
                      <label htmlFor="option1">Option 1</label>
                      <div
                        style={{
                          fontSize: '11px',
                          margin: '5px',
                          color: '#555',
                        }}
                      >
                        I want the physical therapist listed above. You may ask
                        to be paid now, but I also want Medicare billed for an
                        official decision on payment, which is sent to me on a
                        Medicare Summary Notice (MSN). I understand that if
                        Medicare doesn’t pay, I am responsible for payment, but
                        I can appeal to Medicare by following the directions on
                        the MSN. If Medicare does pay, you will refund any
                        payments I made to you, less co-pays or deductibles.
                      </div>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="option2"
                        name="medicareBillingPreference"
                        value="2"
                        onChange={formik.handleChange}
                      />
                      <label htmlFor="option2">Option 2</label>
                      <div
                        style={{
                          fontSize: '11px',
                          margin: '5px',
                          color: '#555',
                        }}
                      >
                        I want the physical therapist listed above, but do not
                        bill Medicare. You may ask to be paid now as I am
                        responsible for payment. I cannot appeal if Medicare is
                        not billed.
                      </div>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="option3"
                        name="medicareBillingPreference"
                        value="3"
                        onChange={formik.handleChange}
                      />
                      <label htmlFor="option3">Option 3</label>
                      <div
                        style={{
                          fontSize: '11px',
                          margin: '5px',
                          color: '#555',
                        }}
                      >
                        I don’t want the physical therapist listed above. I
                        understand with this choice I am not responsible for
                        payment, and I cannot appeal to see if Medicare
                        wouldpay.
                      </div>
                    </div>
                  </fieldset>
                  <fieldset
                    style={{
                      border: 'none',
                      padding: 0,
                      paddingTop: 10,
                      marginBottom: 15,
                    }}
                  >
                    <legend style={{ fontWeight: 'bold' }}>
                      Additional Information
                    </legend>
                    <textarea
                      id="additionalInformation"
                      name="additionalInformation"
                      onChange={formik.handleChange}
                      value={formik.values.additionalInformation}
                      style={{
                        width: '100%',
                        borderRadius: 4,
                        borderColor: '#aaa',
                        height: 120,
                        outline: 'none',
                        padding: 8,
                        resize: 'none',
                      }}
                    />
                  </fieldset>
                </div>
                <Button
                  type="submit"
                  marginTop="30px"
                  width="100%"
                  variant="secondary"
                >
                  Submit
                </Button>
              </form>
            );
          }}
        ></RisePopup>
      ) : null}
    </>
  );
};
