import React from 'react';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { createTransport } from '@sentry/core';
import store from './store/store';
import { ThemeProvider } from 'theme-ui';
import { theme } from 'theme/theme';
import { Router } from 'routing/Router';
import { Flex } from 'rebass';
import { Toaster } from 'react-hot-toast';
import { isDevelopmentMode } from './utils/isDevelopmentMode';
import { baseURL } from 'api/api';

function makeFetchTransport(options: any) {
  function makeRequest(request: any) {
    console.log(options.headers);
    const requestOptions: RequestInit = {
      body: request.body,
      method: 'POST',
      referrerPolicy: 'origin',
      headers: options.headers || {
        'Content-Type': 'text/plain;charset=UTF-8',
      },
      ...options.fetchOptions,
    };

    return fetch(options.url, requestOptions).then(response => {
      return {
        statusCode: response.status,
        headers: {
          'x-sentry-rate-limits': response.headers.get('X-Sentry-Rate-Limits'),
          'retry-after': response.headers.get('Retry-After'),
        },
      };
    });
  }

  return createTransport(options, makeRequest);
}

if (!isDevelopmentMode) {
  Sentry.init({
    dsn:
      'https://84f838f5f9e1148dafb66fc0b0d3517b@o4505450104094720.ingest.us.sentry.io/4505987418226688',
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      // eslint-disable-next-line
      Sentry.reactRouterV5BrowserTracingIntegration({ history }),
      Sentry.replayIntegration(),
    ],
    debug: isDevelopmentMode,
    tunnel: `${baseURL}/sentry`,
    transport: makeFetchTransport,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/api\.petehealth\.com\/api/,
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
// To get the correct height on mobile devices
// Hacky but functional for now
const setVerticalHeight = () => {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};
setVerticalHeight();

window.addEventListener('resize', setVerticalHeight);
window.addEventListener('orientationchange', setVerticalHeight);

export const App: React.FC = () => (
  <Sentry.ErrorBoundary
    fallback={
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <p>An error has occurred</p>
      </div>
    }
  >
    <Flex height="100%">
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Router />
        </Provider>
      </ThemeProvider>
    </Flex>
    <Toaster
      toastOptions={{
        loading: {
          style: {
            background: '#348ce9',
            color: 'white',
          },
        },
        error: {
          style: {
            background: '#e24747',
            color: 'white',
          },
        },
      }}
    ></Toaster>
  </Sentry.ErrorBoundary>
);
