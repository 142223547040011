import React, { useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import {
  EndSessionForm,
  Props as EndSessionFormProps,
} from 'pages/session/SessionPage/EndSessionForm';
import { withRouter, RouteComponentProps } from 'react-router';
import { endSession as endSessionAction } from 'modules/appointments/actions';
import { Appointment, Case, Lead, Patient } from 'types/types';
import {
  CLEAR_EVAL_SESSION_STATE,
  END_EVAL_SESSION_WITH_ERRORS,
  SKIP_EVAL_SESSION_WITH_ERRORS,
  getEvalSessionState,
} from 'reducers/evalSession';
import { getIsFetchingSessionData } from 'selectors/session/getIsFetchingSessionData';
import { getIsMissingDocsNoticesEnabled } from 'selectors/features/getIsMissingDocsNoticesEnabled';

// TODO: These form values are not used, check with client if safe to delete
export interface FormValues {
  checkId: boolean;
  checkPrescription: boolean;
  signEmr: boolean;
}

export interface OwnProps extends RouteComponentProps {
  appointment: Appointment;
  lead?: Lead;
  patient?: Patient;
  therapyCase?: Case;
}

export interface DispatchProps {
  endSession: typeof endSessionAction;
}

export type Props = OwnProps & DispatchProps;

const initialValues: FormValues = {
  checkId: false,
  checkPrescription: false,
  signEmr: false,
};

const mapDispatchToProps: DispatchProps = {
  endSession: endSessionAction,
};

const IGNORED_DOCUMENTS = [
  'Intake Form',
  'Signature',
  'Initials',
  'ABN Form',
  'Lien Form',
];

export const EndSessionComponent = ({
  endSession,
  appointment,
  lead,
  patient,
  therapyCase,
}: Props) => {
  const dispatch = useDispatch();

  const { isEndingSessionWithErrors, isSkippingEndErrors } = useSelector(
    getEvalSessionState
  );
  const isMissingDocsNoticesEnabled = useSelector(
    getIsMissingDocsNoticesEnabled
  );

  const isFetchingSessionData = useSelector(getIsFetchingSessionData);

  const isMissingRequiredDocs = !!lead?.missingDocs?.filter(
    doc => !IGNORED_DOCUMENTS.includes(doc)
  ).length;

  const isEndSessionDisabled =
    isEndingSessionWithErrors && !isSkippingEndErrors;

  const onSubmit = useCallback(() => {
    if (isMissingDocsNoticesEnabled) {
      if (isMissingRequiredDocs && !isSkippingEndErrors) {
        dispatch({ type: END_EVAL_SESSION_WITH_ERRORS });
        return;
      }
      if (isSkippingEndErrors) {
        dispatch({ type: CLEAR_EVAL_SESSION_STATE });
      }
    }
    endSession({ id: appointment.id, missingDocs: lead?.missingDocs || [] });
  }, [endSession, isMissingRequiredDocs, isSkippingEndErrors, appointment.id]);

  const renderEndSessionForm = useCallback(
    (formProps: EndSessionFormProps) => (
      <EndSessionForm
        appointment={appointment}
        lead={lead}
        patient={patient}
        therapyCase={therapyCase}
        isEndSessionDisabled={isEndSessionDisabled}
        isSkippingErrors={isSkippingEndErrors}
        missingDocumentsIgnoredFiles={IGNORED_DOCUMENTS}
        isFetchingSessionData={isFetchingSessionData}
        // @ts-ignore
        onCloseErrorsPopup={() => dispatch({ type: CLEAR_EVAL_SESSION_STATE })}
        // @ts-ignore
        onSkipWithErrors={notes =>
          dispatch({ type: SKIP_EVAL_SESSION_WITH_ERRORS, payload: { notes } })
        }
        {...formProps}
      />
    ),
    [
      appointment,
      lead,
      patient,
      therapyCase,
      isEndSessionDisabled,
      isSkippingEndErrors,
      isFetchingSessionData,
      isMissingRequiredDocs,
    ]
  );

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {renderEndSessionForm}
    </Formik>
  );
};

export const EndSession = connect(
  undefined,
  mapDispatchToProps
)(withRouter(EndSessionComponent));
