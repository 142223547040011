import React, { useCallback } from 'react';
import { Appointment, Case, Doctor, Lead, Patient } from 'types/types';
import { Box, Flex, Text } from 'rebass';
import moment from 'moment';
import { AppFileType, AppFileInputType } from 'utils/file/constants';
import { ValueTextArea } from 'components/ValueTextArea/ValueTextArea';
import { PatientCardLink } from 'components/PatientCardLink/PatientCardLink';
import { useSelector } from 'react-redux';
import { getIsPaymentCardInputEnabled } from 'selectors/features/getIsPaymentCardInputEnabled';
import { getIsCompositeFileUploadEnabled } from '../../../selectors/features/getIsCompositeFileUploadEnabled';
import { CaseFileControls } from '../../Files/CaseFileControls';
import { MissingDocsWrapper } from './MissingDocsWrapper';
import { getIsIntakeFormRequestEnabled } from 'selectors/features/getIsIntakeFormRequestEnabled';

interface Props {
  patient: Patient;
  therapyCase: Case;
  doctor?: Doctor;
  lead: Lead;
  appointment: Appointment;
}

const unknown = 'Unknown';
const dateFormat = 'MM/DD/YYYY';

const ValueText = ({ children }: { children: React.ReactNode }) => (
  <Text
    color="grey"
    sx={{
      display: 'inline',
    }}
  >
    {children}
  </Text>
);

const renderDate = (date: string | null) =>
  date ? moment(date).format(dateFormat) : unknown;

const missingDocByLabel: Record<string, string> = {
  Payment: 'Payment Method',
  'Insurance Card': 'Insurance Card',
  'Insurance Card 2': 'Insurance Card - Secondary',
  RX: 'Rx',
  'ABN Form': 'ABN Form',
  'Lien Form': 'Lien Form',
  'Forms Signature': 'Signature',
  'Form Initials': 'Initials',
  'Intake Form': 'Intake Form',
};

const getIsMissingDocByLabel = (
  label: string,
  missingDocs: string[] | null
) => {
  return !!missingDocs?.includes(missingDocByLabel[label]);
};

export const PatientFileLinks = ({
  appointment,
  patient,
  therapyCase: {
    id: caseId,
    leadId,
    insAvailableVisits,
    lastDrVisit,
    lastVisitAt,
    pocDate,
    scheduledVisitCount,
    totalForecastVisits,
    totalPrescribedVisits,
  },
  doctor,
  lead,
}: Props) => {
  const isPaymentCardInputEnabled = useSelector(getIsPaymentCardInputEnabled);
  const isCompositeUploadEnabled = useSelector(getIsCompositeFileUploadEnabled);
  const isIntakeFormRequestEnabled = useSelector(getIsIntakeFormRequestEnabled);

  const missingDocs = lead?.missingDocs;

  const File = useCallback(
    ({
      appFileType,
      inputType,
    }: {
      appFileType: AppFileType;
      inputType?: AppFileInputType;
    }) => {
      return (
        <CaseFileControls
          appFileType={appFileType}
          inputType={inputType}
          caseId={String(caseId)}
          leadId={String(leadId)}
        />
      );
    },
    [caseId]
  );

  const values = [
    [
      'Physician',
      <ValueText>
        {doctor ? `${doctor.lastName}, ${doctor.firstName}` : 'No doctor'}
      </ValueText>,
    ],
    ['Dr. Visit', <ValueText>{renderDate(lastDrVisit)}</ValueText>],
    ['Last Dr. Visit', <ValueText>{renderDate(lastVisitAt)}</ValueText>],
    ['POC Date', <ValueText>{renderDate(pocDate)}</ValueText>],
    ...(isPaymentCardInputEnabled
      ? [
          [
            'Payment',
            <PatientCardLink
              patient={patient}
              appointmentId={String(appointment.id)}
            />,
          ],
        ]
      : [['Payment', <File appFileType={AppFileType.Payment} />]]),
    ...(isCompositeUploadEnabled
      ? [
          [
            'Insurance Card',
            <File
              inputType={AppFileInputType.Composite}
              appFileType={AppFileType.InsuranceFront}
            />,
          ],
          [
            'Insurance Card 2',
            <File
              inputType={AppFileInputType.Composite}
              appFileType={AppFileType.Insurance2Front}
            />,
          ],
        ]
      : [
          [
            'Insurance Card front',
            <File appFileType={AppFileType.InsuranceFront} />,
          ],
          [
            'Insurance Card back',
            <File appFileType={AppFileType.InsuranceBack} />,
          ],
          [
            'Insurance Card 2 front',
            <File appFileType={AppFileType.Insurance2Front} />,
          ],
          [
            'Insurance Card 2 back',
            <File appFileType={AppFileType.Insurance2Back} />,
          ],
        ]),
    ['RX', <File appFileType={AppFileType.Rx} />],
    [
      'ABN Form',
      <File
        appFileType={AppFileType.AbnForm}
        inputType={AppFileInputType.AbnForm}
      />,
    ],
    [
      'Lien Form',
      <File
        appFileType={AppFileType.LienForm}
        inputType={AppFileInputType.Form}
      />,
    ],
    ['Other', <File appFileType={AppFileType.Other} />],
    ...(isIntakeFormRequestEnabled
      ? [
          [
            'Intake Form',
            <File
              appFileType={AppFileType.IntakeForm}
              inputType={AppFileInputType.Form}
            />,
          ],
        ]
      : [
          [
            'Forms Signature',
            <File
              appFileType={AppFileType.FormSignature}
              inputType={AppFileInputType.FormSignature}
            />,
          ],
          [
            'Form Initials',
            <File
              appFileType={AppFileType.Initials}
              inputType={AppFileInputType.FormSignature}
            />,
          ],
        ]),
    ,
    ['Forecast Visit Count', <ValueText>{totalForecastVisits}</ValueText>],
    ['Next Visit', <ValueText>{lead.futureDrVisit}</ValueText>],
    ['Scheduled', <ValueText>{scheduledVisitCount}</ValueText>],
    ['RX Allowed', <ValueText>{totalPrescribedVisits}</ValueText>],
    ['Insurance Allows', <ValueText>{insAvailableVisits}</ValueText>],
  ];

  return (
    <Flex
      width="100%"
      flexDirection="column"
      sx={{
        fontSize: '10pt',
      }}
    >
      {values.map(([label, value]: any) => {
        const isDocMissing = getIsMissingDocByLabel(
          label as string,
          missingDocs
        );

        return (
          <MissingDocsWrapper
            key={label.toString()}
            isDocMissing={isDocMissing}
          >
            <Flex
              alignItems="center"
              marginBottom={1}
              justifyContent="flex-start"
              flexWrap="wrap"
            >
              <Text>{label}:</Text>
              <Box marginLeft={1}>{value}</Box>
            </Flex>
          </MissingDocsWrapper>
        );
      })}
      <ValueTextArea label="Notes:">{lead.lastNoteToTherapist}</ValueTextArea>
    </Flex>
  );
};
