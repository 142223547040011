import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import React from 'react';
import store from '../store/store';
import { getRefreshToken } from 'selectors/auth/getRefreshToken';
import { fetchGoogleOauth2TokensSuccess } from 'modules/auth/actions';
import { GOOGLE_REFRESH_TOKEN_ENDPOINT } from 'modules/auth/constants';
import { logout } from 'modules/auth/actions';
import {
  connectionStatus,
  currentConnectionStatus,
} from 'utils/connectionStatus';
import toast from 'react-hot-toast';
import { omit } from 'lodash';

export const baseURL = `${process.env.REACT_APP_API_URL}/api`;

export const api = axios.create({
  baseURL,
});

type FormRequestItem = {
  form_type: string;
  config: InternalAxiosRequestConfig;
};

let isRefreshing = false;
let refreshQueue: AxiosRequestConfig[] = [];
const OFFLINE_REQUESTS_STORAGE_KEY = 'qrs';
let offlineRequestsQueue: Array<FormRequestItem> = [];

window.addEventListener('load', () => {
  offlineRequestsQueue = JSON.parse(
    localStorage.getItem(OFFLINE_REQUESTS_STORAGE_KEY) || '[]'
  );
});

connectionStatus.on('online', function() {
  if (offlineRequestsQueue.length) {
    const promises: Promise<AxiosResponse<any, any>>[] = [];
    for (let i = 0; i < offlineRequestsQueue.length; i++) {
      promises.push(
        axios(
          omit(
            offlineRequestsQueue[0].config,
            'transformRequest',
            'transformResponse'
          )
        )
      );
    }
    const toastId = toast.loading('Syncing offline form requests');
    const Message = ({ children, t }: any) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <p style={{ flex: 1, margin: 0, padding: 0 }}>{children}</p>
        <button
          style={{
            background: 'white',
            border: 'none',
            borderRadius: 4,
            padding: '5px 10px',
            marginLeft: 15,
            backgroundColor: '#ededed',
          }}
          onClick={() => toast.dismiss(t.id)}
        >
          Dismiss
        </button>
      </div>
    );
    Promise.all(promises).then(
      () => {
        offlineRequestsQueue = [];
        localStorage.removeItem(OFFLINE_REQUESTS_STORAGE_KEY);
        toast.success(t => <Message t={t}>Form requests were synced</Message>, {
          id: toastId,
          duration: 30000,
        });
      },
      err => {
        console.log(err);
        offlineRequestsQueue = [];
        localStorage.removeItem(OFFLINE_REQUESTS_STORAGE_KEY);
        toast.error(
          t => (
            <Message t={t}>There was an error syncing form requests</Message>
          ),
          { id: toastId, duration: 30000 }
        );
      }
    );
  }
});

const handleOfflineRequest = (config: InternalAxiosRequestConfig<any>) => {
  if (
    currentConnectionStatus === 'offline' &&
    config &&
    config.url?.includes('send_form') &&
    config.data &&
    offlineRequestsQueue.every(
      ({ form_type }: { form_type: string }) =>
        form_type !== config.data.form_type
    )
  ) {
    const controller = new AbortController();
    controller.abort();
    const { form_type } = config.data;
    offlineRequestsQueue.push({
      form_type,
      config,
    });
    localStorage.setItem(
      OFFLINE_REQUESTS_STORAGE_KEY,
      JSON.stringify(offlineRequestsQueue)
    );
    return { ...config, signal: controller.signal };
  }
  return config;
};

api.interceptors.request.use(
  config => handleOfflineRequest(config),
  (error: AxiosError) => error.config as InternalAxiosRequestConfig<any>
);

api.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      const refreshToken = getRefreshToken(store.getState());
      if (refreshToken) {
        if (!isRefreshing) {
          isRefreshing = true;
          // Refresh the access token
          try {
            const response = await api.post(GOOGLE_REFRESH_TOKEN_ENDPOINT, {
              refresh_token: getRefreshToken(store.getState()),
            });
            const { access_token } = response.data;
            // Update the access token in the store
            store.dispatch(fetchGoogleOauth2TokensSuccess(response.data));
            // Retry the original request with the new access token
            originalRequest.headers.Authorization = `Bearer ${access_token}`;
            refreshQueue.push(originalRequest);
            // Process the queued requests
            refreshQueue.forEach(request => {
              request.headers = {
                ...request.headers,
                Authorization: `Bearer ${access_token}`,
              };
              axios(request);
            });
            refreshQueue = [];
          } catch (error) {
            // Redirect to login page if refresh token is invalid or expired
            store.dispatch(logout());
          } finally {
            isRefreshing = false;
          }
        } else {
          // Add the request to the queue
          refreshQueue.push(originalRequest);
        }
      } else {
        // Redirect to login page if refresh token is missing
        store.dispatch(logout());
      }
    }
    return Promise.reject(error);
  }
);
