import React, { useCallback, useState } from 'react';
import { Box, Button, Flex, Text } from 'rebass';
import { HeaderBar } from 'components/HeaderBar';
import { AppointmentDate } from 'components/AppointmentDate/AppointmentDate';
import { History } from 'history';
import { match } from 'react-router-dom';
import { ArrowBackIcon } from 'components/icons/ArrowBackIcon';
import { Appointment, Case, Insurance, Lead, Patient } from 'types/types';
import { PatientInfo } from 'components/PatientInfo/PatientInfo';
import { AppointmentDurationLocationInfo } from 'components/AppointmentDurationLocationInfo/AppointmentDurationLocationInfo';
import { FieldDisplayer } from 'components/FieldDisplayer/FieldDisplayer';
import { CustomTextLink } from 'components/CustomLink/CustomTextLink';
import { FooterButton } from 'components/FooterButton/FooterButton';
import { Map } from 'components/Map/Map';
import { MapPinIcon } from 'components/icons/MapPinIcon';
import { Marker } from 'google-maps-react';
import { getGoogleMapsDirectionsUrl } from 'utils/getGoogleMapsDirectionsUrl';
import { AppointmentPathParams } from 'routing/routePaths';
import { connect, useDispatch, useSelector } from 'react-redux';
import { startSession as startSessionAction } from 'modules/appointments/actions';
import { ScrollBox } from 'components/ScrollBox/ScrollBox';
import { CallButton } from 'components/CallButton/CallButton';
import { getAppointmentCaseProgressionFraction } from 'utils/case/caseProgression/getAppointmentCaseProgressionFraction';
import {
  getCancellationPath,
  getCarePlanPath,
  getReschedulePath,
} from 'routing/routeFunctions';
import { ConnectedAppointmentInfo } from 'containers/ConnectedAppointmentInfo/ConnectedAppointmentInfo';
import { formatCurrency } from 'utils/formatCurrency';
import { ACTION_HOOKS, CallAction } from 'utils/hooks/useCall/config';
import { CallError } from 'components/CallError/CallError';
import { MissingDocumentsPopup } from 'components/MissingDocumentsPopup';
import {
  CLEAR_EVAL_SESSION_STATE,
  SKIP_START_EVAL_SESSION_WITH_ERRORS,
  START_EVAL_SESSION_WITH_ERRORS,
  getEvalSessionState,
} from 'reducers/evalSession';
import { getIsFetchingSessionData } from 'selectors/session/getIsFetchingSessionData';
import { getIsMissingDocsNoticesEnabled } from 'selectors/features/getIsMissingDocsNoticesEnabled';
import { RisePopup } from 'components/RisePopup';
import { HOME_HEALTH_APPOINTMENT_CANCEL } from 'modules/appointments/actionTypes';
import { getIsHomeHealthAlertEnabled } from 'selectors/features/getIsHomeHealthAlertEnabled';

interface OwnProps {
  match: match<AppointmentPathParams>;
  history: History;
  appointment?: Appointment;
  lead?: Lead;
  patient?: Patient;
  insurance?: Insurance;
  therapyCase?: Case;
}

interface DispatchProps {
  startSession: typeof startSessionAction;
}

type Props = OwnProps & DispatchProps;

const mapDispatchToProps: DispatchProps = {
  startSession: startSessionAction,
};

const REQUIRED_DOCUMENTS = ['Intake Form', 'ABN Form', 'Lien Form'];

const ScheduledAppointmentPageComponent = ({
  history,
  appointment,
  lead,
  patient,
  insurance,
  therapyCase,
}: Props) => {
  const dispatch = useDispatch<any>();
  const [hasAskedForHomeTreatment, setHasAskedForHomeTreatment] = useState(
    false
  );
  const [showHomeTreatmentAlert, setShowHomeTreatmentAlert] = useState(false);
  const goBack = useCallback(() => history.goBack(), [history]);

  const { call: startSession, fetchStatus } = ACTION_HOOKS[
    CallAction.StartSession
  ]({});

  const { isStartingSessionWithErrors, isSkippingStartErrors } = useSelector(
    getEvalSessionState
  );

  const isHomeHealthAlertEnabled = useSelector(getIsHomeHealthAlertEnabled);
  const isFetchingSessionData = useSelector(getIsFetchingSessionData);
  const isMissingDocsNoticesEnabled = useSelector(
    getIsMissingDocsNoticesEnabled
  );

  const isMissingRequiredDocs = !!lead?.missingDocs?.some(doc =>
    REQUIRED_DOCUMENTS.includes(doc)
  );

  const isStartSessionDisabled =
    isStartingSessionWithErrors && !isSkippingStartErrors;

  const startThisSession = useCallback(() => {
    if (
      isHomeHealthAlertEnabled &&
      insurance?.insType === 'Medicare' &&
      !hasAskedForHomeTreatment
    ) {
      setShowHomeTreatmentAlert(true);
      setHasAskedForHomeTreatment(true);
      return;
    }
    if (appointment) {
      if (isMissingDocsNoticesEnabled) {
        if (isMissingRequiredDocs && !isSkippingStartErrors) {
          dispatch({ type: START_EVAL_SESSION_WITH_ERRORS });
          return;
        }
        if (isSkippingStartErrors) {
          dispatch({ type: CLEAR_EVAL_SESSION_STATE });
        }
      }
      startSession({ id: appointment.id });
    }
  }, [
    appointment,
    startSession,
    isMissingRequiredDocs,
    isMissingDocsNoticesEnabled,
    isSkippingStartErrors,
    hasAskedForHomeTreatment,
    isHomeHealthAlertEnabled,
  ]);

  const goToCarePlan = useCallback(
    () =>
      history.push(getCarePlanPath({ patientId: String(patient?.id) || '' })),
    [history, patient]
  );

  return (
    <>
      <Flex flexDirection="column" width="100%">
        <HeaderBar
          title={<AppointmentDate appointment={appointment} />}
          left={<ArrowBackIcon size={20} onClick={goBack} />}
        />
        {appointment && patient && (
          <>
            <ScrollBox flexDirection="column">
              {(patient?.lat && patient?.lng && (
                <Map
                  height={210}
                  initialCenter={{ lat: patient.lat, lng: patient.lng }}
                >
                  <Marker />
                </Map>
              )) ||
                null}
              <Flex backgroundColor="muted" padding={2} alignItems="center">
                <MapPinIcon size={30} />
                <Flex flexDirection="column">
                  <Flex>{patient?.address}</Flex>
                  <Flex>
                    {patient?.city}, {patient?.state} {patient?.zipCode}
                  </Flex>
                </Flex>
                <Flex flex={1} />
                <Text
                  as="a"
                  href={getGoogleMapsDirectionsUrl({
                    lat: patient?.lat,
                    lng: patient?.lng,
                  })}
                  variant="link"
                >
                  Google
                </Text>
              </Flex>
              <Flex flexDirection="column" padding={3} width="100%">
                <Flex
                  justifyContent="space-between"
                  marginBottom={2}
                  width="100%"
                >
                  <PatientInfo
                    firstName={patient.firstName}
                    lastName={patient.lastName}
                    service={appointment.service}
                  />
                  <AppointmentDurationLocationInfo appointment={appointment} />
                </Flex>
                <Flex
                  width="100%"
                  justifyContent="space-between"
                  marginBottom={3}
                >
                  <FieldDisplayer title="Insurance" value={patient.insurance} />
                  <FieldDisplayer
                    title="Session"
                    value={
                      therapyCase
                        ? getAppointmentCaseProgressionFraction({
                            therapyCase,
                            appointment,
                          })
                        : 'N/A'
                    }
                  />
                  <Flex>
                    <CallButton number={patient?.phone} size={35} />
                  </Flex>
                </Flex>
                <Box marginBottom={3}>
                  <FieldDisplayer
                    title="Charge copay and/or cancellation fee"
                    value={
                      <Flex>
                        <Text fontWeight="bold" paddingRight={2}>
                          {formatCurrency({
                            amount: appointment.cancellationFee || 0,
                          })}
                        </Text>
                        <CustomTextLink
                          to={getCancellationPath({
                            appointmentId: appointment.id,
                          })}
                        >
                          Cancel appointment
                        </CustomTextLink>
                        <Text marginLeft={2} marginRight={2}>
                          |
                        </Text>
                        <CustomTextLink
                          to={getReschedulePath({
                            appointmentId: appointment.id,
                          })}
                        >
                          Reschedule
                        </CustomTextLink>
                      </Flex>
                    }
                  />
                </Box>
                <Button variant="secondary" onClick={goToCarePlan}>
                  Patient Plan
                </Button>
              </Flex>
              <ConnectedAppointmentInfo appointment={appointment} />
              <Flex flex={1} />
            </ScrollBox>
            <div style={{ position: 'relative' }}>
              {isStartSessionDisabled ? (
                <MissingDocumentsPopup
                  lead={lead}
                  appointment={appointment}
                  patient={patient}
                  therapyCase={therapyCase}
                  // @ts-ignore
                  onClickOverlay={() =>
                    dispatch({ type: CLEAR_EVAL_SESSION_STATE })
                  }
                  // @ts-ignore
                  onSkipWithErrors={notes =>
                    dispatch({
                      type: SKIP_START_EVAL_SESSION_WITH_ERRORS,
                      payload: { notes },
                    })
                  }
                  trackedDocuments={REQUIRED_DOCUMENTS}
                ></MissingDocumentsPopup>
              ) : isSkippingStartErrors ? (
                <Text
                  padding={2}
                  textAlign={'center'}
                  backgroundColor="#444"
                  color="white"
                  fontSize={1}
                >
                  You're starting the session with documents missing.
                </Text>
              ) : null}
              <FooterButton
                onClick={startThisSession}
                disabled={isStartSessionDisabled}
                loading={isFetchingSessionData}
                label="Start Session"
              >
                <CallError fetchStatus={fetchStatus} />
              </FooterButton>
            </div>
          </>
        )}
        {!appointment && (
          <Flex padding={3}>
            Appointment not found
            <Text
              onClick={goBack}
              variant="link"
              paddingLeft={1}
              paddingRight={1}
            >
              go back
            </Text>
          </Flex>
        )}
      </Flex>
      {showHomeTreatmentAlert ? (
        <RisePopup
          onClickOverlay={() => {
            setHasAskedForHomeTreatment(false);
            setShowHomeTreatmentAlert(false);
          }}
          sx={{
            height: '100vh',
            position: 'fixed',
            left: 0,
            bottom: 0,
            zIndex: 9999,
          }}
          render={({ createHideHandler }) => {
            return (
              <HomeHealthWarningCheck
                onCancelVisit={createHideHandler(() => {
                  dispatch({
                    type: HOME_HEALTH_APPOINTMENT_CANCEL,
                    payload: { appointmentId: appointment?.id },
                  });
                  setShowHomeTreatmentAlert(false);
                  setHasAskedForHomeTreatment(false);
                })}
                onProceedWithVisit={createHideHandler(() => {
                  appointment && startThisSession();
                  setShowHomeTreatmentAlert(false);
                })}
              ></HomeHealthWarningCheck>
            );
          }}
        ></RisePopup>
      ) : null}
    </>
  );
};

function HomeHealthWarningCheck({
  onCancelVisit,
  onProceedWithVisit,
}: {
  onCancelVisit: () => void;
  onProceedWithVisit: () => void;
}) {
  const [hasReceivedHomeHealth, setHasReceivedHomeHealth] = useState<
    'unset' | 'yes' | 'no'
  >('unset');

  return (
    <>
      <p style={{ fontSize: 13 }}>
        Have you received any in-home medical services since my last visit? This
        includes blood pressure checks, injections, medications, etc.
      </p>
      <Flex sx={{ gap: 15, height: 44 }}>
        <Button
          variant={hasReceivedHomeHealth === 'yes' ? 'primary' : 'secondary'}
          flex={1}
          onClick={() => setHasReceivedHomeHealth('yes')}
          sx={{ paddingLeft: 0, paddingRight: 0, fontSize: 13 }}
        >
          Yes - Cancel Visit
        </Button>
        <Button
          flex={1}
          variant={hasReceivedHomeHealth === 'no' ? 'primary' : 'secondary'}
          onClick={() => setHasReceivedHomeHealth('no')}
          sx={{ paddingLeft: 0, paddingRight: 0, fontSize: 13 }}
        >
          No
        </Button>
      </Flex>
      {hasReceivedHomeHealth === 'no' ? (
        <>
          <Flex sx={{ marginTop: 30 }}>
            <Button
              flex={1}
              variant={'primary'}
              onClick={onProceedWithVisit}
              sx={{ paddingLeft: 0, paddingRight: 0, fontSize: 13 }}
            >
              Proceed with Visit
            </Button>
          </Flex>
          <p style={{ fontSize: 13 }}>
            <b style={{ color: 'red' }}>Warning:</b> Please be advised that if
            Medicare declines to cover a visit due to concurrent in-home
            services, you will be responsible for the cost of the visit, as
            agreed in your patient consent form. Additionally, in such cases,
            the therapist's compensation for the non-billable visit “may” be
            subject to a clawback.
          </p>
        </>
      ) : null}
      {hasReceivedHomeHealth === 'yes' ? (
        <>
          <Flex sx={{ marginTop: 30 }}>
            <Button
              flex={1}
              variant={'primary'}
              onClick={onCancelVisit}
              sx={{ paddingLeft: 0, paddingRight: 0, fontSize: 13 }}
            >
              Cancel Visit
            </Button>
          </Flex>
          <p style={{ fontSize: 13 }}>
            Operations team will reach out to the patient for further
            coordination.
          </p>
        </>
      ) : null}
    </>
  );
}

export const ScheduledAppointmentPage = connect(
  null,
  mapDispatchToProps
)(ScheduledAppointmentPageComponent);
