import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Flex, Text } from 'rebass';
import { HeaderBar } from 'components/HeaderBar';
import { ArrowBackIcon } from 'components/icons/ArrowBackIcon';
import { AppointmentPathParams } from 'routing/routePaths';
import { History } from 'history';
import {
  Appointment,
  Case,
  Doctor,
  Insurance,
  Lead,
  Patient,
} from 'types/types';
import { match } from 'react-router-dom';
import { SessionInfo } from 'components/SessionInfo/SessionInfo';
import { EndSession } from 'pages/session/SessionPage/EndSession';
import { ClockRenderer } from 'components/ClockRenderer/ClockRenderer';
import { TimeDuration } from 'components/TimeDuration/TimeDuration';
import moment from 'moment';
import { ScrollBox } from 'components/ScrollBox/ScrollBox';
import { ConnectedAppointmentInfo } from 'containers/ConnectedAppointmentInfo/ConnectedAppointmentInfo';
import { CLEAR_EVAL_SESSION_STATE } from 'reducers/evalSession';

interface Props {
  match: match<AppointmentPathParams>;
  history: History;
  appointment: Appointment;
  patient?: Patient;
  therapyCase?: Case;
  doctor?: Doctor;
  lead?: Lead;
  insurance?: Insurance;
}

export const SessionPage = ({
  history,
  appointment,
  patient,
  therapyCase,
  lead,
  insurance,
}: Props) => {
  const dispatch = useDispatch();
  const goBack = useCallback(() => {
    dispatch({ type: CLEAR_EVAL_SESSION_STATE });
    history.goBack();
  }, [history]);

  const renderTimer = useCallback(
    () => (
      <TimeDuration
        moment1={moment(appointment.arrivedAt)}
        moment2={moment()}
      />
    ),
    [appointment]
  );

  return (
    <Flex flexDirection="column" width="100%">
      <HeaderBar
        title="Session Started"
        left={<ArrowBackIcon size={20} onClick={goBack} />}
      />
      <Flex padding={3} paddingBottom={0}>
        <SessionInfo
          appointment={appointment}
          patient={patient}
          lead={lead}
          therapyCase={therapyCase}
        >
          <Flex flexDirection="column">
            <Text paddingBottom={1}>In Session</Text>
            {appointment.arrivedAt ? (
              <Text
                fontWeight="bolder" // This doesn't affect text larger than 14pt, why?
                fontSize="21pt"
                fontFamily="Courier New"
              >
                <ClockRenderer clockSpeed={1000} render={renderTimer} />
              </Text>
            ) : null}
          </Flex>
        </SessionInfo>
      </Flex>
      <ScrollBox height="100%" flexDirection="column">
        <ConnectedAppointmentInfo appointment={appointment} />
      </ScrollBox>
      <Flex flex={1} />
      <EndSession lead={lead} patient={patient} appointment={appointment} />
    </Flex>
  );
};
