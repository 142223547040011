import React, { useEffect } from 'react';
import { useDispatchFunction } from 'utils/hooks/useDispatchFunction';
import { fetchTherapist as fetchTherapistAction } from 'modules/therapist/actions';
import { BugReporterOverlay } from 'containers/BugReporterOverlay/BugReporterOverlay';
import { ConnectionStatusProvider } from 'hooks/useConnectionStatus';

interface Props {
  children: React.ReactNode;
}

export const AppContainer = ({ children }: Props) => {
  const fetchTherapist = useDispatchFunction(fetchTherapistAction);

  useEffect(() => {
    fetchTherapist({});
  }, [fetchTherapist]);

  return (
    <ConnectionStatusProvider>
      <BugReporterOverlay />
      {children}
    </ConnectionStatusProvider>
  );
};
