import { createSelector } from 'reselect';
import { getSchedule } from 'selectors/schedule/getSchedule';
import { GlobalState } from 'reducers';
import { AppointmentStatusName } from 'types/types';

export const getAppointment = createSelector(
  getSchedule,
  (state: GlobalState, { appointmentId }: { appointmentId: number }) =>
    appointmentId,
  (appointments, appointmentId) => {
    const selectedAppointment = appointments.find(
      appointment => appointment.id === appointmentId
    );
    // FOR DEVELOPMENT
    // @ts-ignore
    if (selectedAppointment?.faked) {
      // @ts-ignore
      selectedAppointment.appStatus = a?.faked;
    }
    // END FOR DEVELOPMENT
    return selectedAppointment;
  }
);
